import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const AwardImages = () => {
  return (
    <ul className="grid md:grid-cols-5 gap-5 grid-cols-2 justify-between flex-wrap">
      <li className="flex flex-col items-center justify-start rounded-[1.25rem] border border-cream p-[1.875rem] text-center">
        <StaticImage
          src="../assets/img/top_clutch1.webp"
          alt="Clutch"

          placeholder="blurred"
        />
      </li>
      <li className="flex flex-col items-center justify-start rounded-[1.25rem] border border-cream p-[1.875rem] text-center">
        <StaticImage
          src="../assets/img/top_clutch7.webp"
          alt="GoodFirms"

          placeholder="blurred"
        />
      </li>
      <li className="flex flex-col items-center justify-start rounded-[1.25rem] p-[1.875rem] text-center  bg-[url('../assets/img/mask.webp')] bg-no-repeat bg-cover">
        <StaticImage
          src="../assets/img/global_award_2023.webp"
          alt="Clutch top 928 company badge"

          placeholder="blurred"
        />
      </li>
      <li className="flex flex-col items-center justify-start rounded-[1.25rem] border border-cream p-[1.875rem] text-center">
        <StaticImage
          src="../assets/img/top_clutch5.webp"
          alt="Clutch top 100 company badge"

          placeholder="blurred"
        />
      </li>
      <li className="flex flex-col items-center justify-start rounded-[1.25rem] border border-cream p-[1.875rem] text-center ">
        <StaticImage
          src="../assets/img/top_the_manifest_design_company_ahmedabad_2023_award.webp"
          alt="Clutch top 100 company badge"
          className="w-[70px] md:w-[45px] lg:w-[67px] xl:w-[97px]"
          placeholder="blurred"
        />
      </li>
    </ul>
  )
}

export default AwardImages
