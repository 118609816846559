import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

export const LeaderShipTeam = () => {
  return (
    <ul className="lead flex flex-wrap justify-between">
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/vaibhav.webp"
            alt="Vaibhav Shah"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h3 className="text-lg font-gorditamedium">Vaibhav Shah</h3>
          <p className="text-sm">CEO</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] max-[600px]:mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/ankush.webp"
            alt="Ankush Mathur"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Ankush Mathur</h4>
          <p className="text-sm">Tech Project Manager</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] max-[600px]:mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/nilesh.webp"
            alt="Nilesh Kadivar"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Nilesh Kadivar</h4>
          <p className="text-sm">Marketing Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] max-[600px]:mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/jainam.webp"
            alt="Jainam Shah"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Jainam Shah</h4>
          <p className="text-sm">JavaScript Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/saurabh-r.webp"
            alt="Saurabh Ramani"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Saurabh Ramani</h4>
          <p className="text-sm">MERN Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/ahmed.webp"
            alt="Ahmed Chamadia"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Ahmed Chamadia</h4>
          <p className="text-sm">Mobile Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/mayank.webp"
            alt="Mayank Mishra"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Mayank Mishra</h4>
          <p className="text-sm">Frontend Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/vishal.webp"
            alt="Vishal Parvani"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Vishal Parvani</h4>
          <p className="text-sm">UI/UX Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/goldey.webp"
            alt="Goldy Benedict"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Goldy Benedict</h4>
          <p className="text-sm">Content Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/mayur.webp"
            alt="Mayur Ghadia"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Mayur Ghadia</h4>
          <p className="text-sm">PHP Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/manohar.webp"
            alt="Manohar Kumar"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Manohar Kumar</h4>
          <p className="text-sm">QA Lead</p>
        </div>
      </li>
      <li className="md:max-w-[calc(25%-0.938rem)] w-full flex flex-col rounded-[1.25rem] border border-[#d8d8d8] mt-[1.875rem] overflow-hidden">
        <div className="overflow-hidden relative mt-[-5px]">
          <StaticImage
            src="../assets/img/jinal.webp"
            alt="Jinal Raval"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="p-5">
          <h4 className="text-lg font-gorditamedium">Jinal Raval</h4>
          <p className="text-sm">Sr. HR</p>
        </div>
      </li>
    </ul>
  )
}

export const Teammates = () => {
  const { allTeamMatesJson: { teamMates } } = useStaticQuery(graphql
    `query {
      allTeamMatesJson {
        teamMates: nodes {
          name
          designation
          imgStr {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }`
  )

  return (
    <ul className="flex flex-wrap overflow-hidden m-[-0.313rem]">
      {
        teamMates.map((member, index) => (
          <li key={index} className="w-1/3 md:max-w-[calc(10%-0.625rem)] bg-[#f7f8f9] lg:max-w-[calc(12.5%-0.625rem)] overflow-hidden cursor-pointer relative border border-[#d8d8d8] rounded-[0.625rem] m-[0.313rem] xl:max-h-[130px] max-h-[110px] p-4 pb-0 hover:bg-[#F8F7F2] transition-all max-w-[calc(33.3%-0.625rem)]">
            <GatsbyImage
              image={getImage(member.imgStr)!}
              alt={member.name}
              className="w-full"
            />
            <div className="con flex flex-col justify-center items-start p-2 bg-white-200 absolute w-full bottom-[-7.188rem] left-0 transition-all ease-in-out duration-500">
              <p className="text-xs text-black font-gorditamedium pb-1 text-left">{member.name}</p>
              <span className="text-black text-[0.625rem] leading-3 text-left">{member.designation}</span>
            </div>
          </li>
        ))
      }
    </ul>
  )
}
