import { StaticImage } from "gatsby-plugin-image"
import React from "react"
const BannerImages = () => {
  return (
    <ul className="gallery flex w-[160000px] translate-[3d(0px, 0px, 0px)] animate-[moveSlideshow_10s_linear_alternate_infinite]">
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life1.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[30rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life2.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
        <StaticImage
          src="../assets/img/life3.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life4.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life5.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15rem] w-full"
        />
      </li>

      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life6.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
        <StaticImage
          src="../assets/img/life7.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life8.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[30rem] w-full"
        />
      </li>

      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life9.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
        <StaticImage
          src="../assets/img/life10.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life11.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life12.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life13.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
        <StaticImage
          src="../assets/img/life14.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[15.25rem] w-full"
        />
      </li>
      <li className="flex flex-col mx-1.5">
        <StaticImage
          src="../assets/img/life15.png"
          alt="Techuz Office"
          title=""
          placeholder="blurred"
          className="my-1.5 max-w-[30rem] w-full"
        />
      </li>
    </ul>
  )
}

export default BannerImages
