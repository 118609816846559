import { HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import AwardImages from "../components/AwardImages"
import BannerImages from "../components/BannerImages"
import Contact from "../components/Contact"
import SEO from "../components/SEO"
import { LeaderShipTeam, Teammates } from "../components/Teammates"
import useSiteMetadata from "../hooks/useSiteMetadata"
import BatchIcon from "../assets/img/batch-icon.inline.svg"
import TeamIcon from "../assets/img/team-icon.inline.svg"
import TestedIcon from "../assets/img/tested.inline.svg"
import RoiIcon from "../assets/img/roi.inline.svg"
const CompanyPage = () => {
  return (
    <>
      <section className="pt-[7.5rem] pb-10">
        <div className="container">
          <div className="title-sec m-auto w-full max-w-[57.188rem] text-center">
            <h6 className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italic mb-5  font-gorditamedium text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">About us</h6>
            <h1 className="text-black text-4xl leading-[1] pb-5 lg:text-[3.25rem] text-center font-blancomedium">
              We love building <span className="italic text-primary">exceptional products</span>
              <span className="inline-block text-orangecol">.</span>
            </h1>
            <p className="text-[1.125rem] leading-[1.875rem] font-gorditaregular text-black text-center">
              Well, that’s what drives us to our desks every day — building products that delight users, that solve business challenges, that make the world a better place, and that deliver promises.  And, yes, we can do it for you too.
            </p>
          </div>
        </div>
      </section>
      <section className="achivement-sec lg:pb-[6.875rem] md:pb-[3.75rem] pb-16">
        <div className="tech-slideshow relative overflow-hidden">
          <BannerImages />
        </div>
        <div className="md:pt-[3.75rem] lg:pt-[6.25rem] pt-16 w-full">
          <div className="container">
            <h2 className="text-black text-[1.375rem] font-gorditamedium leading-8 text-center m-auto max-w-[46.875rem] w-full">
              We've helped businesses increase their revenue on an average by
              90% in their first year with us!
            </h2>
            <ul className="flex flex-wrap justify-between md:pt-10 pt-6 lg:px-10">
              <li
                className="md:max-w-[calc(33.3%-0.625rem)] w-full mt-8 md:mt-0 rounded-[1.25rem] md:p-10 p-10 px-5 flex flex-col items-center justify-center border-2 border-cream">
                <div className="text-center">
                  <h2 className="text-black md:text-[2.625rem] lg:text-[3.25rem] font-gorditamedium lg:leading-[3.5rem] leading-none">
                    150+
                  </h2>
                  <p className="text-base leading-[1.7rem] pt-2 font-gorditaregular text-black md:text[1.125rem] md:leading[1.875rem]">
                    <span className="font-gorditamedium">Satisfied Clients </span>
                    Across the Globe
                  </p>
                </div>
              </li>
              <li
                className="md:max-w-[calc(33.3%-0.625rem)] w-full mt-8 md:mt-0 rounded-[1.25rem] md:p-10 p-10 px-5 flex flex-col items-center justify-center bg-[url('../assets/img/mask.webp')] bg-no-repeat bg-cover">
                <div className="text-center">
                  <h2 className="text-black md:text-[2.625rem] lg:text-[3.25rem] font-gorditamedium lg:leading-[3.5rem] leading-none">
                    250+
                  </h2>
                  <p className="text-base leading-[1.7rem] pt-2 font-gorditaregular text-black md:text[1.125rem] md:leading[1.875rem]">
                    <span className="font-gorditamedium">Projects delivered </span>
                    successfully
                  </p>
                </div>
              </li>
              <li
                className="md:max-w-[calc(33.3%-0.625rem)] w-full mt-8 md:mt-0 rounded-[1.25rem] md:p-10 p-10 px-5 flex flex-col items-center justify-center border-2 border-cream">
                <div className="text-center">
                  <h2 className="text-black md:text-[2.625rem] lg:text-[3.25rem] font-gorditamedium lg:leading-[3.5rem] leading-none">
                    75+
                  </h2>
                  <p className="text-base leading-[1.7rem] pt-2 font-gorditaregular text-black md:text[1.125rem] md:leading[1.875rem]">
                    <span className="font-gorditamedium">Experts </span>
                    under the same roof
                  </p>
                </div>
              </li>
            </ul>
            <div className="lg:pt-28 md:pt-[3.75rem] pt-16">
              <div className="w-full text-center pb-12">
                <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium">
                  What can you <span className="italic text-primary">expect from us</span>
                  <span className="inline-block text-orangecol">.</span>
                </h2>
              </div>
              <div className="flex flex-wrap justify-between">
                <div className="w-full lg:max-w-[41%] max-sm:hidden">
                  <StaticImage
                    src="../assets/img/unique.webp"
                    alt="Strive for excellence, passion for action, creator-friendly & customer-centric"
                    className="w-full sm:max-w-[29.063rem] hidden lg:block"
                    placeholder="blurred"
                  />
                </div>
                <div className="w-full lg:max-w-[51.5%] pt-0">
                  <div className="pb-10">
                    <TeamIcon className="max-w-[4rem]" />
                    <h3 className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italic mb-5 mt-7  font-gorditamedium text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">Highly skilled people</h3>
                    <p className="leading-[1.625rem]">
                      Techuz is known for its exceptionally skilled team, who are
                      best in class in their fields and technologies. We are
                      extremely diligent in selecting our team members and only
                      hire people who comply with our high-quality standards and
                      company culture. We are also focused on investing in our
                      people and keeping them up to date with the latest skills
                      and trends. So working with Techuz means you’re working with
                      the best of the best talents in the industry.
                    </p>
                  </div>
                  <div className="pb-10">
                    <TestedIcon className="max-w-[4rem]" />
                    <h3 className="btch-big bg-cream inline-block rounded-[6.25rem] text-[0.875rem] italic mb-5 mt-7  font-gorditamedium text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">Tested and efficient process</h3>
                    <p className="leading-[1.625rem]">
                      With hundreds of projects delivered in the last decade, we
                      have tested and optimized our process for maximum
                      efficiency. Working with us will ensure you get speedy
                      delivery, adaptability, predictability, and tangible
                      results. All in all, your product development will be
                      handled to maximize the value.
                    </p>
                  </div>
                  <div>
                    <RoiIcon className="max-w-[4rem]" />
                    <h3 className="btch-big bg-[#E8FBFF] inline-block rounded-[6.25rem] text-[0.875rem] italic mb-5 mt-7  font-gorditamedium text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">Unmatched ROI</h3>
                    <p className="leading-[1.625rem]">
                      We know product development is a huge investment. But when
                      you do it right, with the right development partner, the
                      returns you reap are even more massive. At Techuz, we’re
                      committed to making it possible. We provide unparalleled
                      quality, expertise, and experience to each of our clients
                      for maximum return on investment.
                    </p>
                  </div>
                </div>
                <StaticImage
                  src="../assets/img/unique.webp"
                  alt="Strive for excellence, passion for action, creator-friendly & customer-centric"
                  className="w-full sm:max-w-[29.063rem] lg:hidden block mt-12"
                  placeholder="blurred"
                />
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="leadership pb-16">
        <div className="container">
          <div className="title-sec m-auto w-full max-w-[51.25rem] pb-12">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center pb-5">
              Meet <span className="line-through">our</span> <span className="italic text-primary"> your team</span>
              <span className="inline-block text-orangecol">.</span>
            </h2>
            <p className="leading-[1.7rem] text-center">
              We’re a team of experts from various disciplines—technology, design, business, media—passionate about creating and delivering excellent products.
            </p>
          </div>
          <LeaderShipTeam />
        </div>
      </section>

      <section className="leadership">
        <div className="container">
          <div className="team">
            <Teammates />
          </div>
        </div>
      </section >

      <section className="lg:py-[6.875rem] md:py-[3.75rem] py-10">
        <div className="container">
          <div className="title-sec pb-10">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center">
              Feathers in our cap
              <span className="inline-block text-orangecol">.</span>
            </h2>
          </div>
          <AwardImages />
        </div>
      </section>
      <Contact />
    </>
  )
}

export default CompanyPage

export const Head: HeadFC = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <SEO
      title="About Techuz InfoWeb – Journey, Culture and Our Expert Team Group"
      canonical={`${siteUrl}${location.pathname}`}
      description="As a reputed IT company, our expert team is committed to offering top-notch web and mobile app development solutions using advanced cutting-edge technologies for Startups, SMBs and Enterprises around the world."
    />
  )
}
